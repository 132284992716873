<template>
  <div class="w-full flex flex-col items-center">
    <div class="w-11/12">
      <n-form
        v-if="isCustomSignup"
        ref="customFormRef"
        :model="signupInfo"
        :rules="signupElementsRules"
      >
        <n-form-item
          v-for="element in signupElements"
          :key="element.id"
          :path="element.attribute"
          :label="element.label"
          :class="{ hidden: element.hidden }"
        >
          <n-input
            v-if="element.type === 'string'"
            @keydown.enter.prevent
            v-model:value="signupInfo[element.attribute]"
            :placeholder="element.placeholder"
          />
          <vue-tel-input
            v-else-if="element.type === 'phoneNumber'"
            v-model="signupInfo[element.attribute]"
            :inputOptions="{ placeholder: element.placeholder }"
            :mode="'international'"
            :styleClasses="['w-full', 'h-9']"
            :defaultCountry="countryCode"
          />
          <n-select
            v-else-if="element.type === 'select'"
            v-model:value="signupInfo[element.attribute]"
            :placeholder="element.placeholder"
            :options="getFieldOptions(element)"
          />
          <c-date-picker
            v-else
            v-model:value="signupInfo[element.attribute]"
            :placeholder="element.placeholder"
            :max="currentDate"
          />
        </n-form-item>
      </n-form>
      <legacy-customer-fields
        ref="legacyFormRef"
        v-else
        v-model:signupInfo="signupInfo"
        :predefined-rules="predefinedRules"
        :custom-fields="customFields"
      />
      <delivery-code-field
        v-if="$route.query.addPoints"
        v-model:value="deliveryCode"
      />
      <referral-code-field v-model:value="referralCode" />
      <div class="flex flex-row items-start mt-5">
        <n-checkbox v-model:checked="policyCheck"> </n-checkbox>
        <div
          class="flex flex-col sm:flex-row items-start sm:items-center text-secondary ml-2"
        >
          <div
            @click="handleClickPolicy"
            class="whitespace-nowrap"
            v-html="$t('policy-accept')"
          ></div>
          <div
            class="ml-1"
            @click="handleClickTerms"
            v-html="$t('terms-accept')"
          ></div>
        </div>
      </div>
      <div class="mt-3 flex flex-row items-start text-secondary">
        <n-checkbox v-model:checked="signupInfo.marketingCommunication">
        </n-checkbox>
        <div class="ml-2">
          {{
            signupPage.marketingCommunicationSentence ||
            $t("want-to-receive-promotions", {
              organizationName: authStore.organization.name,
            })
          }}
        </div>
      </div>
    </div>
    <div class="mt-8 mb-10">
      <n-button
        type="primary"
        :loading="loading"
        size="large"
        @click="createCustomer"
        >{{ $t("send") }}</n-button
      >
    </div>
  </div>
  <c-loading-screen v-if="waitingNotificationsApproval" :show-spinner="false" />
</template>

<script>
import { mapStores, mapState } from "pinia";
import { authStore } from "@/stores/auth";
import api from "@/api.js";
import moment from "moment";
import { useNotification, useDialog } from "naive-ui";
import LegacyCustomerFields from "./LegacyCustomerFields.vue";
import DeliveryCodeField from "./DeliveryCodeField.vue";
import ReferralCodeField from "./ReferralCodeField.vue";
import CDatePicker from "../../../core-ui/src/components/CDatePicker.vue";
import CLoadingScreen from "../../../core-ui/src/components/CLoadingScreen.vue";
import metaPixel from "@/metaPixel.js";

export default {
  name: "SignupForm",
  data() {
    return {
      signupInfo: { customerCustomFields: {} },
      policyCheck: false,
      loading: false,
      notificationCreator: null,
      dialogCreator: null,
      deliveryCode: null,
      referralCode: null,
      waitingNotificationsApproval: false,
    };
  },
  components: {
    DeliveryCodeField,
    ReferralCodeField,
    LegacyCustomerFields,
    CDatePicker,
    CLoadingScreen,
  },
  mounted() {
    if (this.$route.query.referralCode) {
      this.referralCode = this.$route.query.referralCode;
    }
    this.notificationCreator = useNotification();
    this.dialogCreator = useDialog();
    this.fillDefaultValues();
  },
  computed: {
    ...mapStores(authStore),
    ...mapState(authStore, [
      "organizationConfig",
      "signupElements",
      "signupPage",
      "customFields",
      "serviceWorkerRegistration",
      "countryCode",
    ]),
    currentDate() {
      return moment().subtract(1, "year").format("YYYY-MM-DD");
    },
    mappedSelectedCustomFields() {
      return Object.keys(this.signupInfo)
        .filter((k) => k.includes("customField."))
        .reduce((res, key) => {
          let customFieldId = key.split(".")[1];
          res[customFieldId] = this.signupInfo[key];
          return res;
        }, {});
    },
    isCustomSignup() {
      return (
        this.signupElements?.find((e) => e.attribute === "email") !== undefined
      );
    },
    deviceIsIphone() {
      return [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform);
    },
    formattedBirthDate() {
      if (!this.signupInfo.birthDate) {
        return undefined;
      } else {
        return moment(this.signupInfo.birthDate).format("yyyy-MM-DD");
      }
    },
    signupElementsRules() {
      return (
        this.signupElements?.reduce((acc, element) => {
          if (element.mandatory) {
            let predefinedValidator =
              this.predefinedRules[element.attribute]?.validator;

            let mandatoryValidator = (rule, value) => {
              if (!value || value === "") {
                return new Error(this.$t("mandatory-field-error"));
              }
              return true;
            };
            acc[element.attribute] = {
              validator: (rule, value) => {
                if (predefinedValidator) {
                  let predefinedValidation = predefinedValidator(rule, value);
                  if (predefinedValidation instanceof Error) {
                    return predefinedValidation;
                  } else {
                    return mandatoryValidator(rule, value);
                  }
                } else {
                  return mandatoryValidator(rule, value);
                }
              },
              trigger: "blur",
            };
          }
          return acc;
        }, {}) || {}
      );
    },
    predefinedRules() {
      return {
        email: {
          validator: (rule, value) => {
            if (!value || !value.includes("@")) {
              return new Error(this.$t("email-must-be-valid"));
            }
            return true;
          },
          trigger: "blur",
        },
        phoneNumber: {
          validator: (rule, value) => {
            if (
              !this.organizationConfig.phoneNumberMandatory &&
              (!value || value === "")
            )
              return true;
            if (!this.$utils.parsePhoneNumberFromString(value)?.isPossible()) {
              return new Error(this.$t("phone-must-be-valid"));
            }
            return true;
          },
          trigger: "blur",
        },
      };
    },
    slugName() {
      return this.$router.currentRoute.value.params.slugName;
    },
  },
  methods: {
    fillDefaultValues() {
      this.signupElements?.forEach((element) => {
        if (element.defaultValue) {
          this.signupInfo[element.attribute] = element.defaultValue;
        }
      });
    },
    getFieldOptions(field) {
      if (field.attribute.includes("customField")) {
        let customField = this.customFields.find(
          (customField) => customField.id === field.attribute.split(".")[1]
        );
        return customField?.options.map((option) => ({
          label: option,
          value: option,
        }));
      }
    },
    redirectToLastapp(phoneNumber) {
      if (this.$route.query.lastappUrl) {
        let paramsComponent = "";
        if (phoneNumber) {
          paramsComponent = `&customerPhone=${phoneNumber}`;
        }
        window.location.replace(this.$route.query.lastappUrl + paramsComponent);
      }
    },
    handleClickTerms() {
      this.notificationCreator.create({
        title: this.$t("terms-and-conditions-title"),
        type: "info",
        content: this.$t("terms-and-conditions-message"),
        duration: 10000,
      });
    },
    handleClickPolicy() {
      let url;
      if (this.organizationConfig?.privacyPolicyUrl) {
        url = this.organizationConfig.privacyPolicyUrl;
      } else {
        const routeData = this.$router.resolve({
          name: "policy",
        });
        url = routeData.href;
      }
      window.open(url, "_blank");
    },
    async askForNotifications() {
      if (this.deviceIsIphone) return false;
      this.waitingNotificationsApproval = true;
      let result = await Notification.requestPermission();
      this.waitingNotificationsApproval = false;
      return result === "granted";
    },
    async createCustomer() {
      let notificationsApproved;
      try {
        notificationsApproved = await this.askForNotifications();
      } catch {
        console.log("Notifications not granted");
      }
      let notificationCreator = this.notificationCreator;
      let form = this.isCustomSignup
        ? this.$refs.customFormRef
        : this.$refs.legacyFormRef;
      try {
        await form.validate();
      } catch (e) {
        notificationCreator.create({
          title: this.$t("form-errors"),
          type: "error",
          content: this.$t("form-errors-message"),
          duration: 3500,
        });
        return;
      }
      if (!this.policyCheck) {
        this.notificationCreator.create({
          title: this.$t("warning"),
          type: "error",
          content: this.$t("must-accept-privacy-policy"),
          duration: 3500,
        });
        return;
      }
      this.loading = true;
      let response;
      try {
        response = await api.post(
          `/organization/${this.authStore.organization.id}/createCustomer`,
          {
            customer: {
              ...this.signupInfo,
              birthDate: this.formattedBirthDate,
            },
            source:
              this.$route.query?.source ||
              this.signupPage?.customersSource ||
              "",
            customFields: this.isCustomSignup
              ? this.mappedSelectedCustomFields
              : this.signupInfo.customerCustomFields,
            locationId:
              this.slugName && this.slugName !== ""
                ? this.authStore.locationsBySlug[this.slugName]?.id
                : undefined,
            countryCode: this.countryCode,
            deliveryCode: this.deliveryCode,
            referralCode: this.referralCode,
            passId: this.authStore.passId,
            welcomeEmailTemplateId: this.signupPage?.welcomeEmailTemplateId,
            shouldSendRecoveryEmail:
              !this.deviceIsIphone || !this.$route.query.lastappUrl,
            deviceIsIphone: this.deviceIsIphone,
          }
        );
        await metaPixel.signupComplete();
        let customer = response?.data.customer;
        this.authStore.updateCurrentCustomer(customer);
        if (notificationsApproved) {
          await this.handlePushSubscription(customer.id);
        }
        if (this.$route.query.lastappUrl) {
          this.redirectToLastapp(customer.phoneNumber);
        } else {
          this.$router.push({
            name: "downloadPage",
          });
        }
      } catch (e) {
        this.handleCreationError(e);
      }
      this.loading = false;
    },
    async handlePushSubscription(customerId) {
      let pushSubscription =
        await this.serviceWorkerRegistration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: import.meta.env
            .VITE_APPLICATION_SERVER_KEY_PUBLIC,
        });
      await api.post(`/customer/${customerId}/savePushSubscription`, {
        pushSubscription,
      });
    },
    handleCreationError(e) {
      if (e.response?.data?.deliveryCodeSuccess) {
        this.notificationCreator.create({
          title: this.$t("points-added-title"),
          type: "success",
          content: this.$t("points-added-message"),
          duration: 3500,
        });
      }
      if (e.response.status === 409) {
        if (this.$route.query.lastappUrl) {
          if (this.deviceIsIphone) {
            this.dialogCreator.success({
              title: this.$t("account-linked-title"),
              content: this.$t("account-linked-message-1"),
              positiveText: this.$t("continue-ordering"),
              onPositiveClick: () => {
                this.redirectToLastapp(
                  this.$utils.formatNumber(this.signupInfo.phoneNumber)
                );
              },
            });
          } else {
            this.dialogCreator.success({
              title: this.$t("continue-ordering-2"),
              content: this.$t("account-linked-message-2", {
                coveredEmail: e.response.data.coveredEmail,
              }),
              positiveText: this.$t("continue-ordering-1"),
              onPositiveClick: () => {
                this.redirectToLastapp(
                  this.$utils.formatNumber(this.signupInfo.phoneNumber)
                );
              },
            });
          }
        } else {
          if (this.deviceIsIphone) {
            this.$router.push({
              name: "recoveryPage",
              query: {
                ...this.$route.query,
              },
            });
          } else {
            this.dialogCreator.warning({
              title: this.$t("account-already-exists-title"),
              content: this.$t("account-already-exists-message", {
                coveredEmail: e.response.data.coveredEmail,
              }),
              positiveText: this.$t("accept"),
            });
          }
        }
      } else {
        throw e;
      }
    },
  },
};
</script>

<style scoped></style>
