<template>
  <div
    class="flex flex-col h-screen bg-gray-200 items-center justify-center text-blue text-center"
  >
    <div class="mx-3 text-blue font-bold mx-20">
      {{ $t("signup-page-not-found") }}
    </div>
    <div class="text-gray-400 mt-3">
      [ID: {{ $route.params.shortenedSignupPageId }}]
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFoundPage",
};
</script>

<style scoped></style>
