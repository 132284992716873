<template>
  <div class="flex justify-center text-secondary space-y-4 py-4">
    <div class="flex justify-center text-2xl">
      <div class="font-bold uppercase">
        {{ $t("thank-you") }}
      </div>
    </div>
    <div class="flex justify-center">
      {{ $t("rating-received") }}
    </div>
    <div class="flex justify-center text-xl py-2 uppercase font-semibold">
      {{ $t("come-again") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "RatingFinish",
};
</script>

<style></style>
