<template>
  <div
    class="flex flex-col items-center min-h-screen"
    :style="{ 'background-color': branding.backgroundColor }"
    :class="{ 'blur-md pointer-events-none': blocked }"
  >
    <c-image
      v-if="!isInIframe"
      :image-id="coverImage"
      class="h-64 w-full"
      :mode="isMobile ? 'fill' : 'crop'"
    />
    <div class="w-5/6">
      <div
        class="absolute floatting rounded-3xl overflow-hidden m-auto border-2 border-white shadow-md shadow-black"
        :class="{ 'rounded-full': signupPage?.circleLogo }"
        v-if="shouldShowLogo"
      >
        <c-image
          :image-id="branding.logoImageId"
          class="h-48 w-48"
          mode="fit"
        />
      </div>
      <div :class="{ 'mt-32': shouldShowLogo, 'mt-10': !shouldShowLogo }">
        <RouterView v-if="initialised" />
      </div>
    </div>
  </div>
</template>

<script>
import CImage from "../../../core-ui/src/components/CImage.vue";
import { authStore } from "@/stores/auth";
import { mapActions, mapGetters, mapState } from "pinia";
import api from "@/api.js";

export default {
  name: "PageShell",
  data() {
    return {
      blocked: false,
    };
  },
  async mounted() {
    try {
      await this.refreshSignupInfo();
      this.refreshCountryCode();
    } catch (e) {
      console.error(e);
      if (this.handleUrlRedirect()) {
        console.log("redirecting");
        return;
      } else {
        this.$router.replace({ name: "notFound" });
        return;
      }
    }
    if (this.organizationConfig?.missingPaymentBlock) {
      this.$notificationGenerator.create({
        title: this.$t("deactivated-page-title"),
        type: "error",
        closable: false,
        content: this.$t("deactivated-page-message"),
        duration: 1200000,
      });
      this.blocked = true;
    }
    if (this.$router.currentRoute.value.name === "ratingPage") return;
    if (this.$route.query?.replaceCustomerId) {
      let customer = (
        await api.get(`/customer/${this.$route.query.replaceCustomerId}`)
      ).data;
      this.updateCurrentCustomer(customer);
    }
    if (this.currentCustomer?.organizationId === this.organization.id) {
      if (this.handleUrlRedirect()) return;
      if (this.$route.query?.addPoints) {
        this.$router.replace({
          name: "addPointsPage",
          query: { ...this.$route.query, addPoints: undefined },
        });
      } else if (this.$router.currentRoute.value.name !== "addPointsPage") {
        this.$router.replace({
          name: "downloadPage",
          query: { ...this.$route.query },
        });
      }
    }
  },
  methods: {
    ...mapActions(authStore, [
      "refreshSignupInfo",
      "refreshCountryCode",
      "updateCurrentCustomer",
    ]),
    handleUrlRedirect() {
      if (this.$route.query?.lastappUrl) {
        console.log("Redirecting to lastappUrl");
        let paramsComponent = "";
        if (
          this.currentCustomer?.phoneNumber &&
          this.currentCustomer?.organizationId === this.organization?.id
        ) {
          paramsComponent = `&customerPhone=${this.currentCustomer.phoneNumber}`;
        }
        window.location.replace(this.$route.query.lastappUrl + paramsComponent);
        return true;
      }
    },
  },
  computed: {
    ...mapGetters(authStore, ["locationsBySlug"]),
    ...mapState(authStore, [
      "locations",
      "initialised",
      "currentCustomer",
      "organization",
      "organizationConfig",
      "branding",
      "signupPage",
    ]),
    shouldShowLogo() {
      return (
        !this.isInIframe && (!this.signupPage || !this.signupPage.hiddenLogo)
      );
    },
    coverImage() {
      if (this.isMobile) {
        return (
          this.branding.signupMobileCoverImageId || this.branding.coverImageId
        );
      } else {
        return (
          this.branding.signupDesktopCoverImageId ||
          this.branding.signupMobileCoverImageId ||
          this.branding.coverImageId
        );
      }
    },
    isMobile() {
      return window.innerWidth < 640;
    },
    slugName() {
      return this.$route.params?.slugName;
    },
    isInIframe() {
      return window.self !== window.top;
    },
  },
  components: {
    CImage,
  },
};
</script>

<style scoped>
.floatting {
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
