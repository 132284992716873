<template>
  <div>
    <div class="font-bold text-center text-secondary">
      {{ $t("account-found") }}
    </div>
    <div class="mt-10 text-secondary">
      {{ $t("email-with-code") }}
    </div>
    <div class="mt-5">
      <n-input :placeholder="$t('add-six-code')" v-model:value="recoveryCode" />
    </div>
    <div class="mt-8 flex flex-row justify-center">
      <n-button type="primary" size="large" @click="sendRecoveryCode">{{
        $t("access-acount")
      }}</n-button>
    </div>
  </div>
</template>

<script>
import api from "@/api.js";
import { mapActions } from "pinia";
import { authStore } from "@/stores/auth";

export default {
  name: "RecoveryPage",
  data() {
    return {
      recoveryCode: "",
    };
  },
  methods: {
    ...mapActions(authStore, ["updateCurrentCustomer"]),
    async sendRecoveryCode() {
      let response = await api.post("/customer/recoveryCode", {
        recoveryCode: this.recoveryCode,
      });
      let customer = response?.data.customer;
      this.updateCurrentCustomer(customer);
      location.reload();
    },
  },
};
</script>

<style scoped></style>
