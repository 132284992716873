<template>
  <div
    class="flex flex-col"
    :style="{ 'background-color': branding.backgroundColor }"
  >
    <div class="flex justify-center px-4">
      <rating-body
        v-if="!this.ratingFinish"
        class="flex flex-col"
        @ratingFinished="changeView"
      />
      <rating-finish v-else class="flex flex-col" />
    </div>
  </div>
</template>

<script>
import RatingBody from "./RatingBody.vue";
import RatingFinish from "./RatingFinish.vue";
import { authStore } from "../stores/auth";
import { mapState } from "pinia";

export default {
  name: "RatingPage",
  components: {
    RatingBody,
    RatingFinish,
  },
  data() {
    return {
      ratingFinish: false,
    };
  },
  methods: {
    changeView() {
      this.ratingFinish = true;
    },
  },
  computed: {
    ...mapState(authStore, ["branding", "organization"]),
    visitId() {
      return this.$route.query.visitId;
    },
    rating() {
      return parseInt(this.$route.query.rate);
    },
  },
};
</script>

<style></style>
