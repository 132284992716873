<template>
  <div class="flex justify-center">
    <div class="flex justify-center text-2xl text-secondary uppercase">
      <div class="flex flex-row text-center font-bold space-x-1">
        <div v-if="customer">{{ customer }},</div>
        <div>{{ $t("let-opinion") }}</div>
      </div>
    </div>
    <div class="bg-white px-10 py-5 rounded-xl mt-5">
      <div class="flex justify-center text-center text-gray-400">
        {{ $t("make-review") }}
      </div>
      <div class="rating flex justify-center">
        <input
          type="radio"
          name="rating"
          value="5"
          id="5"
          v-model="picked"
        /><label class="flex flex-1 justify-center" for="5">{{ star }}</label>
        <input
          type="radio"
          name="rating"
          value="4"
          id="4"
          v-model="picked"
        /><label class="flex flex-1 justify-center" for="4">{{ star }}</label>
        <input
          type="radio"
          name="rating"
          value="3"
          id="3"
          v-model="picked"
        /><label class="flex flex-1 justify-center" for="3">{{ star }}</label>
        <input
          type="radio"
          name="rating"
          value="2"
          id="2"
          v-model="picked"
        /><label class="flex flex-1 justify-center" for="2">{{ star }}</label>
        <input
          type="radio"
          name="rating"
          value="1"
          id="1"
          v-model="picked"
        /><label class="flex flex-1 justify-center" for="1">{{ star }}</label>
      </div>
    </div>

    <div class="mb-8 mt-5">
      <n-input
        big
        :placeholder="getPlaceholderText()"
        v-model:value="comment"
        :max-length="510"
        input-type="textarea"
      />
    </div>
    <n-button
      type="primary"
      :loading="loading"
      size="large"
      @click="saveRating"
      >{{ $t("save") }}</n-button
    >
    <div
      class="mt-3 flex text-center text-xl py-2 text-secondary uppercase font-semibold"
    >
      {{ $t("come-again") }}
    </div>
  </div>
</template>

<script>
import api from "@/api.js";
import { mapState } from "pinia";
import { authStore } from "../stores/auth";

export default {
  name: "RatingBody",
  data() {
    return {
      star: "☆",
      comment: null,
      picked: "",
    };
  },
  props: {
    customer: {
      type: String,
      default: null,
    },
  },
  async mounted() {
    this.picked = this.rating;
  },
  methods: {
    async saveRating() {
      await api.post(`/rating`, {
        visitId: this.visitId,
        comment: this.comment,
        score: this.picked,
      });
      this.$emit("ratingFinished");
    },
    setLocale() {
      this.$i18n.locale = this.language;
    },
    getPlaceholderText() {
      return this.$t("comments-placeholder");
    },
  },
  computed: {
    ...mapState(authStore, ["organization"]),
    visitId() {
      return this.$route.query.visitId;
    },
    rating() {
      return parseInt(this.$route.query.rate);
    },
  },
  watch: {
    language() {
      this.setLocale();
    },
  },
  components: {},
};
</script>

<style>
.rating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.rating > input {
  display: none;
}

.rating > label {
  position: relative;
  width: 1.1em;
  font-size: 42px;
  color: #ffd700;
  cursor: pointer;
}

.rating > label::before {
  content: "\2605";
  position: absolute;
  opacity: 0;
}

.rating > label:hover:before,
.rating > label:hover ~ label:before {
  opacity: 1 !important;
}

.rating > input:checked ~ label:before {
  opacity: 1;
}

.rating:hover > input:checked ~ label:before {
  opacity: 0.4;
}

html {
  height: 100%;
}
</style>
