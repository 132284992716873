<template>
  <div
    class="h-full flex flex-col items-center justify-center pb-10 overflow-y-scroll"
  >
    <div class="text-secondary font-bold text-2xl">
      {{ $t("privacy-policy-title") }}
    </div>
    <div class="px-10 mt-10 text-secondary">
      {{ $t("privacy-policy-1") }}
      <br /><br /><b
        ><b> {{ $t("privacy-policy-2") }}</b></b
      ><br /><br />
      {{ organization.name }} <br /><br /><b> {{ $t("privacy-policy-3") }}</b
      ><br /><br />
      {{ $t("privacy-policy-4") }}
      <br /><br /><b> {{ $t("privacy-policy-5") }}</b
      ><br /><br />
      {{ $t("privacy-policy-6") }}: <br /><br />
      • {{ $t("privacy-policy-7") }} <br /><br />
      • {{ $t("privacy-policy-8") }} <br /><br />
      <b>{{ $t("privacy-policy-9") }}</b
      ><br /><br />
      {{ $t("privacy-policy-10") }}<br /><br />
      • {{ $t("privacy-policy-11") }}<br /><br />
      <b>{{ $t("privacy-policy-12") }}</b
      ><br /><br />
      {{ $t("privacy-policy-13") }}
      <br /><br />
      • {{ $t("privacy-policy-14") }}<br /><br />
      • {{ $t("privacy-policy-15") }} <br /><br />
      • {{ $t("privacy-policy-16") }} <br /><br />
      • {{ $t("privacy-policy-17") }} <br /><br />
      {{ $t("privacy-policy-18") }} <br /><br />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { authStore } from "@/stores/auth";

export default {
  name: "PolicyPage",
  data() {
    return {};
  },
  async mounted() {
    await this.refreshSignupInfo();
  },
  computed: {
    ...mapState(authStore, ["branding", "organization"]),
  },
  methods: {
    ...mapActions(authStore, ["refreshSignupInfo"]),
  },
};
</script>

<style scoped></style>
