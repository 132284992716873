import { parsePhoneNumberFromString } from 'libphonenumber-js'
import moment from 'moment'

const colors = [
    "#FF7878",
    "#7142A0",
    "#D55283",
    "#35BF8D",
    "#E55934",
    "#4299E1",
    "#3A6890",
    "#21BECB",
    "#FFD166",
    "#795548",
    "#5F3C54",
];

const languageOptions = [
    { value: 'es', label: 'Español' },
    { value: 'en', label: 'Inglés' },
    { value: 'fr', label: 'Francés' },
    { value: 'de', label: 'Alemán' },
    { value: 'ca', label: 'Catalán' },
    { value: 'gl', label: 'Gallego' },
    { value: 'eu', label: 'Euskera' },
]
  
function stringHashCode(string) {
    let hash = 0,
      i,
      chr;
    if (!string || string.length === 0) return hash;
    for (i = 0; i < string.length; i++) {
      chr = string.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
  }

function getHashColor(string) {
    return colors[this.stringHashCode(string) % colors.length];
}

function getSlug(string){
    return string.replace('|', '').replace(/\s+/g, '-').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

function replaceAll(str, find, replace) {
    if (!str) return str
    return str.split(find).join(replace)
}

const currencyFormatters = {
  EUR: new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR'
  }),
  USD: new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: 'USD'
  }),
  MXN: new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN'
  }),
  COP: new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP'
  }),
  CLP: new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP'
  })
}

const currencyFormattersWithoutDecimal = {
  EUR: new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }),
  USD: new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }),
  MXN: new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }),
  COP: new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }),
  CLP: new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
}

let countryCodeCurrencyMapper = {
  ES: 'EUR',
  US: 'USD',
  MX: 'MXN',
  CO: 'COP',
  CL: 'CLP'
}

function currencyFilter(value, countryCode = 'ES', withoutDecimal = false) {
  let currency = countryCodeCurrencyMapper[countryCode]
  let currencyFormattersToUse = withoutDecimal ? currencyFormattersWithoutDecimal : currencyFormatters
  if (!currency) {
    // Fall back to euro with unsupported currencies, but this shouldn't happen
    return currencyFormattersToUse['EUR'].format(value / 100)
  }
  return currencyFormattersToUse[currency].format(value / 100)
}

function getNthColor(n) {
  return colors[n % colors.length]
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

function formatNumber(phoneNumber, countryCode) {
  if (!phoneNumber || phoneNumber === '') return undefined
  let previousNumber = parsePhoneNumberFromString(phoneNumber)
  if (previousNumber?.countryCallingCode) {
    return previousNumber.number
  } else {
    let parsedNumber = parsePhoneNumberFromString(
      phoneNumber,
      countryCode || 'ES'
    )
    return parsedNumber ? parsedNumber.number : phoneNumber
  }
}

function hexToRgb(hexColor) {
  // Convertir el color hexadecimal a RGB
  if (!hexColor) return null;
  const hex = hexColor.replace(
    /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
    (m, r, g, b) => r + r + g + g + b + b
  );
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return rgb
    ? {
        r: parseInt(rgb[1], 16),
        g: parseInt(rgb[2], 16),
        b: parseInt(rgb[3], 16),
      }
    : null;
}

function getContrastColor(originalColor) {
  let rgbColor = hexToRgb(originalColor);
  if (!rgbColor) return "#000000";
  // Calcular el índice de contraste del color
  const luminance =
    (0.299 * rgbColor.r + 0.587 * rgbColor.g + 0.114 * rgbColor.b) / 255;
  const contrast = luminance > 0.5 ? "#000000" : "#FFFFFF";
  // Devolver el color de texto adecuado según el contraste
  return contrast;
}

function isSameBirthdayWeek(dateToCheck) {
  let iteratorDate = moment().startOf('isoWeek')
  dateToCheck = moment(dateToCheck)
  let remainingDaysToCheck = 7
  while (remainingDaysToCheck) {
      --remainingDaysToCheck
      let check = iteratorDate.date() == dateToCheck.date() && iteratorDate.month() === dateToCheck.month()
      if (check) return true
      iteratorDate.add(1, 'day')
  }
  return false
}

function darken(color, percent = 10) {
  if (!color) return color;
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);
  R = Math.min(parseInt((R * (100 - percent)) / 100), 255);
  G = Math.min(parseInt((G * (100 - percent)) / 100), 255);
  B = Math.min(parseInt((B * (100 - percent)) / 100), 255);

  var RR =
    R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  var GG =
    G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  var BB =
    B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

export default {
    stringHashCode,
    getHashColor,
    getSlug,
    getNthColor,
    currencyFilter,
    sleep,
    formatNumber,
    replaceAll,
    getContrastColor,
    parsePhoneNumberFromString,
    isSameBirthdayWeek,
    languageOptions,
    darken
}