<template>
  <div></div>
</template>

<script>
import api from "@/api.js";

export default {
  name: "RatingRedirect",
  async mounted() {
    if (this.rating === 5) {
      this.ratingLink = (
        await api.get(`/visit/${this.visitId}/location/ratingLink`)
      ).data.ratingLink;
      console.log({ ratingLink: this.ratingLink });
      if (this.ratingLink) {
        await api.post(`/rating`, {
          visitId: this.visitId,
          score: this.rating,
        });
        window.location.href = this.ratingLink;
      }
    } else {
      this.$router.replace({ name: "ratingPage", query: this.$route.query });
    }
  },
  computed: {
    visitId() {
      return this.$route.query.visitId;
    },
    rating() {
      return parseInt(this.$route.query.rate);
    },
  },
};
</script>
